<template>
  <div>
    <ba-toolbar
      :toolbarProps="toolbarProps"
      @add="addCase()"
    />
    <ba-filter
      :placeholder="$t('ba-library.filter.search')"
      :position="'end'"
      :cols="3"
      :value="value"
      @input="searchData"
    />
    <ba-table
      :tableProps="tableProps"
      :params="params"
      :loader="loader"
      @reload="getCases"
      @selected="setCase"
      :pagination="true"
    />
  </div>
</template>

<script>
import BaToolbar from './../../../components/Toolbar.vue'
import BaTable from './../../../components/Table.vue'
import BaFliter from './../../../components/Filter.vue'
import i18n from '@/plugins/i18n'
export default {
  components: {
    'ba-toolbar': BaToolbar,
    'ba-table': BaTable,
    'ba-filter': BaFliter
  },
  props: {
    idContact: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      value: '',
      toolbarProps: {
        title: i18n.t('case.components.toolbar.titleTable'),
        actions: [
          {
            type: 'add',
            label: i18n.t('case.components.toolbar.createButton'),
            icon: 'mdi-plus',
            route: 'case',
            color: 'primary'
          }
        ]
      },
      tableProps: {
        items: [],
        headers: [
          { label: 'N°', value: 'refnum', icon: 'mdi-keyboard', visible: false },
          { label: 'Asunto', value: 'asunto', icon: 'mdi-pencil', visible: true },
          { label: 'Contacto', value: 'idcontactodesc', icon: 'mdi-account', visible: false },
          { label: 'Cuenta', value: 'codelords', icon: 'mdi-account-details', visible: false },
          { label: 'Usuario Asignado', value: 'idusuarioasignadodesc', icon: 'mdi-swap-horizontal', visible: false },
          { label: 'Estado', value: 'idestadodesc', icon: 'mdi-update', visible: true },
          { label: 'Canal', value: 'origen', icon: 'mdi-web', visible: false },
          { label: 'SLA', value: 'porcentaje', icon: 'mdi-clock', visible: true }
        ],
        type: 'case'
      },
      params: {
        idcontacto: 0,
        pagina: 1,
        cantidad: 10,
        filtrobuscar: '',
        cantPages: 1
      },
      loader: false
    }
  },
  methods: {
    searchData (value) {
      this.params.idcontacto = this.idContact
      this.params.filtrobuscar = value
      this.getCases(this.params)
    },
    getCases (params, scrollable) {
      if (scrollable) {
        this.loader = true
      }
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('caseStore/getAllCases', params).then(caseResponse => {
        caseResponse.data.sort((a, b) => new Date(b.fechacreacionstr) - new Date(a.fechacreacionstr))
        if (scrollable) {
          this.tableProps.items.push(...caseResponse.data)
        } else {
          this.params.pagina = 1
          this.tableProps.items = caseResponse.data
          this.params.cantPages = caseResponse.pagination.cantPages
        }
        this.$store.dispatch('loader/done')
        this.loader = false
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    setCase (data) {
      this.$router.push({ name: 'case', query: { caseId: data.id } })
    },
    addCase () {
      this.$router.push({ name: 'case', query: { contactId: this.idContact } })
    }
  },
  mounted () {
    this.$store.dispatch('parameter/getAllParameters')
  }
}
</script>
<style>
</style>
