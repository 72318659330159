<template>
  <div>
    <ba-empty-view
      :text="$t('ba-library.table.nodata')"
      v-if="tableProps.items.length === 0"
    />
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
        v-for="(item, index) of tableProps.items"
        :key="index"
      >
        <ba-card
          :item="item"
          :styleType="tableProps.type"
          :headers="tableProps.headers"
          :hover="true"
          @selected="setItem(item)"
        />
      </v-col>
    </v-row>
    <div v-if="!pagination">
      <div v-if="loader">
        <ba-progress :shape="'linear'" />
      </div>
    </div>
    <div v-if="pagination">
      <ba-pagination
        v-if="tableProps.items.length > 0"
        :params="params"
        @reload="reload"
      />
    </div>
  </div>
</template>

<script>
import BaProgress from './Progress.vue'
import BaEmptyView from './Empty.vue'
import BaPagination from './Pagination.vue'
import BaCard from './Card.vue'

export default {
  name: 'BaTable',
  components: {
    'ba-progress': BaProgress,
    'ba-empty-view': BaEmptyView,
    'ba-pagination': BaPagination,
    'ba-card': BaCard
  },
  props: {
    tableProps: {
      type: Object,
      default () {
        return {
          headers: [],
          items: [],
          type: 'contact'
        }
      }
    },
    params: {
      type: Object,
      default: null
    },
    loader: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getAvatar (item) {
      if (item.nombre && item.apellido) {
        return item.nombre.charAt(0).toUpperCase() + item.apellido.charAt(0).toUpperCase()
      }
      return 'BA'
    },
    parseList (item) {
      if (item && (typeof item === typeof [])) {
        return item.join(' - ')
      }
      return item
    },
    onScroll () {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          if (!this.pagination) {
            this.params.pagina = this.params.pagina + 1
            this.reload(this.params, true)
          }
        }
      }
    },
    reload (params, scrollable) {
      this.$emit('reload', params, scrollable) // (event emitter, request params, show scrollable loader)
    },
    setItem (item) {
      this.$emit('selected', item)
    }
  },
  mounted () {
    this.onScroll()
  }
}
</script>

<style>
.ba-card-header {
  border-bottom: 1px solid #dae1e7;
}
.ba-card-text {
  font-size: 1rem;
}
</style>
