<template>
  <v-row :justify="position">
    <v-col
      :cols="12"
      :md="cols"
      :lg="cols"
    >
      <v-text-field
        :placeholder="placeholder"
        v-model="input"
        @input="updateInput"
        @change="updateInput"
        outlined
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'ba-filter',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: i18n.t('ba-library.filter.search')
    },
    position: {
      type: String,
      default: 'end'
    },
    cols: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      input: ''
    }
  },
  watch: {
    value: {
      handler (after) {
        this.input = after
      },
      immediate: true
    }
  },
  methods: {
    updateInput () {
      setTimeout(() => {
        this.$emit('input', this.input)
      }, 1000)
    }
  },
  beforeMount () {
    this.updateInput()
  }
}
</script>

<style>
.filter {
  width: 20%;
}
</style>
