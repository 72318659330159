<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div>
          <v-icon large>
            {{ icon }}
          </v-icon>
        </div>

      </v-col>
      <v-col>
        <h5 class="mb-5">
          {{ text }}
        </h5>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ba-empty-view',
  props: {
    text: {
      type: String,
      default: 'Sin datos'
    },
    icon: {
      type: String,
      default: 'mdi-magnify'
    }
  }
}
</script>
