<template>
<v-form
    class="mt-4"
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <v-row class="mx-1">
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        class="required-label"
        :label="`${$t(`${baseTranslate}.identifier`)}`"
        outlined
        :rules="required"
        v-model="value.identifier"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        class="required-label"
        :label="`${$t(`${baseTranslate}.name`)}`"
        outlined
        :rules="required"
        v-model="value.name"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        class="required-label"
        :label="`${$t(`${baseTranslate}.lastName`)}`"
        outlined
        :rules="required"
        v-model="value.lastName"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        :label="$t(`${baseTranslate}.email`)"
        outlined
        :rules="value.email[0] ? email : []"
        v-model="value.email[0]"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        :label="$t(`${baseTranslate}.phone`)"
        outlined
        v-model="value.phone[0]"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-text-field
        :label="$t(`${baseTranslate}.address`)"
        outlined
        v-model="value.address"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-select
        v-model="value.group"
        :items="groups"
        item-text="nombre"
        item-value="id"
        chips
        :label="$t(`${baseTranslate}.group`)"
        multiple
        outlined
      ></v-select>
    </v-col>
  </v-row>
</v-form>
</template>

<script>
import { required, emailNoRequieed } from './../../../../utils/validations'
export default {
  props: {
    value: Object
  },
  data: () => {
    return {
      baseTranslate: 'contact.components.basicInfo',
      groups: [],
      required,
      email: emailNoRequieed,
      valid: false
    }
  },
  beforeMount () {
    this.getGoups()
  },
  methods: {
    getGoups () {
      this.$store.dispatch('group/getGroups')
        .then(groupResponse => {
          this.groups = groupResponse
        })
        .catch(err => console.error(err))
    },
    validateForm () {
      return this.$refs.form.validate()
    }
  },
  watch: {
    value: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }

}
</script>

<style>
</style>
