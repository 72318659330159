// TODO: this array should be in the locale file too, but is too much.
const cityes = require('./data.json')

const typeDocuments = [
  'DNI',
  'CARNET DE EXTRANJERIA',
  'PASAPORTE',
  'DESCONOCIDO'
]

const sexs = [
  'FEMENINO',
  'MASCULINO',
  'OTRO'
]

const instructions = [
  'ANALFABETO',
  'CENTROS ESPECIALES',
  'PRIMARIA COMPLETA',
  'PRIMARIA INCOMPLETA',
  'SUPERIOR COMPLETA',
  'SECUNDARIA COMPLETA',
  'SECUNDARIA INCOMPLETA',
  'TECNICO SUPERIOR COMPLETO',
  'TECNICO SUPERIOR INCOMPLETA'
]

const civilState = [
  'CASADO',
  'DIVORCIADO',
  'CONVIVIENTE',
  'SOLTERO',
  'VIUDO',
  'SEPARADO'
]

const condition = [
  'CONTINUADOR',
  'NUEVO'
]

const benefit = [
  'AMISTAD',
  'FAMILIAR',
  'INTERESADO',
  'OTRAS PERSONAS'
]

const typeOfDisease = [
  'TRANSMISIBLE',
  'NO TRANSMISIBLE',
  'N.A.'
]

const typeOfSecure = [
  'SIS GRATUITO',
  'SIS EMPRENDEDOR',
  'SIS MICROEMPRESA',
  'SIS INDEPENDIENTE',
  'ESSALUD',
  'SEGURO PRIVADO',
  'SANIDAD FFAA',
  'SANIDAD FFPP',
  'NINGUNO'
]

const medium = [
  'AFICHE',
  'AMISTAD',
  'BANNER / BANDEROLA',
  'CENTRAL TELEFONICA MINSA',
  'FOLDER',
  'FAMILIAR',
  'OTRAS PAGINAS DE INTERNET',
  'NO BRINDA DATO',
  'OTROS MATERIALES Y MEDIOS DE DIFUSIÓN',
  'OTRAS INSTITUCIONES PÚBLICAS O PRIVADAS',
  'PERIODICO',
  'PERSONAL MINSA',
  'PERSONAL DE OTRAS INSTITUCIONES',
  'RADIO',
  'REDES SOCIALES',
  'REVISTA',
  'TELEVISIÓN',
  'VOLANTE / DIPTICO/ TRIPTICO',
  'PAGINA WEN MINSA'
]

const populationCharasteristichs = [
  'N1',
  'G',
  'LGTB',
  'PDIS',
  'PPL',
  'PPSQ',
  'PVVS',
  'VVF',
  'VVP',
  'VVS',
  'REF',
  'TRAMREF',
  'GA',
  'PTRS',
  'PONC'
]

const derivationCall = [
  'ENFERMERÍA',
  'CORONAVIRUS',
  'NUTRICIÓN',
  'SALUD MENTAL',
  'OBSTETRICIA',
  'OTRAS CONSULTAS',
  'SIS',
  'MEDICINA'
]

const getDepartment = () => {
  return Object.keys(cityes)
}

const getProvince = (department) => {
  return department ? Object.keys(cityes[department]) : []
}

const getDistrict = (department, province) => {
  return province ? cityes[department][province] : []
}

export default {
  typeDocuments,
  sexs,
  instructions,
  civilState,
  condition,
  benefit,
  typeOfDisease,
  typeOfSecure,
  medium,
  populationCharasteristichs,
  derivationCall,
  getDepartment,
  getProvince,
  getDistrict
}
