<template>
    <v-container id="progress">
      <v-row>
        <v-col
          cols="4"
          offset="4"
        >
          <div class="text-center my-5">
            <div v-if="shape === 'circular'">
              <v-progress-circular
                indeterminate
                :color="color"
              ></v-progress-circular>
            </div>
            <div v-if="shape === 'linear'">
              <v-progress-linear
                :color="color"
                indeterminate
                rounded
                height="3"
              ></v-progress-linear>
            </div>
            <div class="text-center mt-3">
              <span>{{ text }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import i18n from '@/plugins/i18n'

export default {
  props: {
    text: {
      type: String,
      default: i18n.t('ba-library.progress.charging')
    },
    shape: {
      type: String,
      default: 'circular'
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style>
</style>
