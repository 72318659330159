<template>
  <v-row class="mx-1">
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.populationCharasteristich`)"
        v-model="value.populationCharasteristich"
        :items="select.populationCharasteristichs"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.instruction`)"
        v-model="value.instruction"
        :items="select.instructions"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.civilState`)"
        v-model="value.civilState"
        :items="select.civilState"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.benefit`)"
        v-model="value.benefit"
        :items="select.benefit"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.typeOfSecure`)"
        v-model="value.typeOfSecure"
        :items="select.typeOfSecure"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.typeDocument`)"
        v-model="value.typeDocument"
        :items="select.typeDocuments"
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-autocomplete
        :label="$t(`${baseTranslate}.department`)"
        v-model="value.department"
        :items="select.getDepartment()"
        outlined
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-autocomplete
        :disabled="!value.department"
        :label="$t(`${baseTranslate}.province`)"
        v-model="value.province"
        :items="select.getProvince(value.department)"
        outlined
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-autocomplete
        :disabled="!value.province"
        :label="$t(`${baseTranslate}.district`)"
        v-model="value.district"
        :items="select.getDistrict(value.department,value.province)"
        outlined
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-select
        :label="$t(`${baseTranslate}.sex`)"
        v-model="value.sex"
        :items="select.sexs"
        outlined
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import DataInfoSelect from './DataInformation'
export default {
  name: 'ba-data-info',
  props: {
    value: Object
  },
  data: () => {
    return {
      baseTranslate: 'contact.components.dataInfo',
      select: DataInfoSelect
    }
  },
  watch: {
    value: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
