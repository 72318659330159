import util from '../../../plugins/utils'
export default {
  mapContactToBasicInfo: (contact) => ({
    identifier: contact.identificador,
    name: contact.nombre,
    lastName: contact.apellido,
    email: contact.email,
    phone: contact.fono,
    address: contact.direccion,
    group: contact.gruposkills
  }),
  mapBasicInfoToRequestObject: (basicInfo, cf, id) => ({
    id,
    cf: cf,
    origin: window.location.hostname,
    identificador: basicInfo.identifier,
    nombre: basicInfo.name,
    apellido: basicInfo.lastName,
    email: basicInfo.email[0] ? basicInfo.email : [`minsa.sincorreo+${basicInfo.identifier}@gmail.com`],
    gruposkills: basicInfo.group,
    direccion: basicInfo.address
  }),
  mapDataInfoToCustomFiels: (dataInfo) => ({
    caracteristica_poblacional: util.getValueForObjectAttribute(dataInfo.populationCharasteristich),
    grado_institucion: util.getValueForObjectAttribute(dataInfo.instruction),
    estado_civil: util.getValueForObjectAttribute(dataInfo.civilState),
    beneficiario: util.getValueForObjectAttribute(dataInfo.benefit),
    tipo_seguro: util.getValueForObjectAttribute(dataInfo.typeOfSecure),
    tipo_documento: util.getValueForObjectAttribute(dataInfo.typeDocument),
    departamento_residencia: util.getValueForObjectAttribute(dataInfo.department),
    provincia_residencia: util.getValueForObjectAttribute(dataInfo.province),
    distrito_residencia: util.getValueForObjectAttribute(dataInfo.district),
    sexo: util.getValueForObjectAttribute(dataInfo.sex)
  }),
  mapCustomFielsToDataInfo: (cf) => ({
    populationCharasteristich: cf.caracteristica_poblacional,
    instruction: cf.grado_institucion,
    civilState: cf.estado_civil,
    benefit: cf.beneficiario,
    typeOfSecure: cf.tipo_seguro,
    typeDocument: cf.tipo_documento,
    department: cf.departamento_residencia,
    province: cf.provincia_residencia,
    district: cf.distrito_residencia,
    sex: cf.sexo
  })
}
