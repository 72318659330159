<template>
  <ba-container>
    <ba-toolbar
      :toolbarProps="toolbarProps"
      @back="goToList()"
      @save="saveContact()"
    />
    <ba-basic-info
      v-model="basicInfo"
      ref="basicInfoComponent"
    />
    <ba-expansion :title="$t('contact.components.dataInfo.title')">
      <ba-data-info v-model="dataInfo" />
    </ba-expansion>
    <ba-expansion
      v-if="contactId"
      :title="$t('contact.components.notes.title')"
      :icon="'mdi-clipboard-edit'"
    >
      <ba-notes
        :id-object="contactId"
        type-object="contactos"
      />
    </ba-expansion>
    <ba-expansion
      v-if="contactId"
      :title="$t('contact.components.cases.title')"
      :icon="'mdi-keyboard'"
    >
      <ba-case :idContact="contactId" />
    </ba-expansion>
  </ba-container>
</template>

<script>
import BaToolbar from './../../../components/Toolbar.vue'
import BaContainer from './../../../components/Container.vue'
import BaExpansion from './../../../components/Expansion.vue'
import BaNotes from './../../../components/Notes.vue'
import BaBasicInfo from './components/BasicInformation.vue'
import BaDataInfo from './components/DataInformation.vue'
import BaCase from './../Cases/CaseList.vue'
import i18n from '@/plugins/i18n'
import mapper from './mapper'
import { Agente_contacto as AContact } from 'portalba360'

export default {
  components: {
    'ba-basic-info': BaBasicInfo,
    'ba-data-info': BaDataInfo,
    'ba-toolbar': BaToolbar,
    'ba-container': BaContainer,
    'ba-expansion': BaExpansion,
    'ba-notes': BaNotes,
    'ba-case': BaCase
  },
  data: () => {
    return {
      contact: new AContact(),
      // TODO: chose one for the rest of the logic
      contactId: undefined,
      toolbarProps: {
        title: i18n.t('contact.components.toolbar.title'),
        actions: [
          {
            type: 'back',
            label: i18n.t('contact.components.toolbar.backButton'),
            icon: 'mdi-arrow-left-bold',
            route: 'contact',
            color: 'secondary'
          },
          {
            type: 'save',
            label: i18n.t('contact.components.toolbar.saveButton'),
            icon: 'mdi-content-save',
            route: 'contact',
            color: 'primary'
          }
        ]
      },
      basicInfo: {
        identifier: '',
        name: '',
        lastName: '',
        email: [''],
        phone: [''],
        address: '',
        group: [1]
      },
      dataInfo: {
        typeDocument: '',
        sex: '',
        instruction: '',
        civilState: '',
        benefit: '',
        typeOfSecure: '',
        populationCharasteristich: '',
        department: '',
        province: '',
        district: ''
      },

      identifier: null
    }
  },
  beforeMount () {
    this.contactId = this.$route.query.contactId
    if (this.contactId) {
      this.getContact(this.contactId)
      this.toolbarProps.title = i18n.t('contact.components.toolbar.titleDetail')
      this.toolbarProps.actions[1].label = i18n.t('contact.components.toolbar.updateButton')
    }
  },
  methods: {
    goToList () {
      console.log('BackButton')
      this.$router.push({ name: 'externalContactList' })
    },
    saveContact () {
      if (this.$refs.basicInfoComponent.validateForm()) {
        const cf = mapper.mapDataInfoToCustomFiels(this.dataInfo)
        const data = mapper.mapBasicInfoToRequestObject(this.basicInfo, cf, this.contactId)
        if (this.contactId) {
          this.$store.dispatch('contact/updateContact', data)
            .then(contact => {
              console.log(contact)
              this.$store.dispatch('snackbar/alert', { type: 'success', msg: `${this.$t('contact.components.snackbar.updateContact')}` })
              this.$router.push({ name: 'externalContact', query: { contactId: contact.id } })
            })
            .catch(err => this.$store.dispatch('snackbar/alert', { type: 'error', msg: `${this.$t('contact.components.snackbar.requestError')} : ${err.message}` }))
        } else {
          this.$store.dispatch('contact/addContact', data)
            .then(contact => {
              console.log(contact)
              this.$router.push({ name: 'externalContact', query: { contactId: contact.id } })
            })
            .catch(err => console.log(err))
        }
      }
    },
    getContact (id) {
      this.$store.dispatch('contact/getContact', { id })
        .then(contact => {
          console.log(contact)
          this.basicInfo = mapper.mapContactToBasicInfo(contact)
          this.dataInfo = mapper.mapCustomFielsToDataInfo(contact.cf)
        })
        .catch(err => console.log(err))
    }
  }

}
</script>
